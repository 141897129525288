<!--电能设置--基本设置---->
<template>
    <div id="energyAnalysisYearData" ref="" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="header-title">
            <span>{{ $t("energy.enedata063") }}</span>
        </div>

        <div class="header">
            <div class="header-left">
                <div class="header-condition">
                    <span>{{ $t("energy.enedata060") }}</span>
                    <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                        <a-radio-button  v-for="(itme, index) in this.eneKindList"
                            :key="index"
                            :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                </div>
            
                <div class="header-condition" v-if="rpt < '4'">
                    <span>{{ $t("energy.enedata061") }}</span>
                    <a-select v-model="leveling" style="width: 120px" :disabled="defaultStatistic==1" @change="levelingTypeChange">
                        <a-select-option
                            v-for="(itme, index) in this.levelingTypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt < '4'">
                    <span>{{ $t("energy.enedata020") }}</span>
                    <a-select v-model="basicUnit" style="width: 120px" @change="basicUnitChange">
                        <a-select-option
                            v-for="(itme, index) in this.basicUnitList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt=='1'">
                    <span>{{ $t("energy.enedata002") }}</span>
                    <a-select v-model="dayType" style="width: 120px" @change="dayTypeChange" :disabled="typeDisable">
                        <a-select-option
                            v-for="(itme, index) in this.dayTypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt=='4'">
                    <span>{{ $t("energy.enedata021") }}</span>
                    <a-select v-model="co2Type" style="width: 120px" @change="co2TypeChange">
                        <a-select-option
                            v-for="(itme, index) in this.co2TypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
        </div>

        <div class="header">
            <div class="header-left"> 
                <div class="header-condition">
                    <span>{{ $t("energy.enedata005") }}</span>
                    <a-select v-model="year" style="width: 120px" @change="yearChange">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition">
                    <span>{{$t("energy.enedata398")}}</span>
                    <a-switch v-model="msel" @change="mselChange" :disabled="(leveling=='1'&&(rpt!='4'||rpt!='5'))" :style="{'width':'60px','margin-top':'5px'}" />
                </div>
                <div class="header-condition">
                    <span>{{$t("energy.enedata399")}}</span>
                    <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
                    <a-select-option
                        v-for="(itme, index) in this.monthList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

               <!-- 显示对象 -->
               <div class="header-condition">
                    <span>{{ $t("energy.enedata004") }}</span>
                    <a-radio-group v-model="chartKind" button-style="solid" @change="drawCharts">
                        <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                        <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                        <a-radio-button value="pie">{{ $t("energy.enedata015") }}</a-radio-button>
                    </a-radio-group>
                </div>
            </div>
    
            <div>
                <a-button  type="primary" style="width: 120px"  @click="search()"> {{ $t("energy.enedata006") }} </a-button>
            </div>
        </div>

        <div class="main">
            <div id="energyAnalysisYearDataChart" :style="echartStyle" ></div>   
            <div>
                <div style="text-align:right;" v-if="data!=null && data.length>0">
                    <a-button  type="primary" style="width: 120px"  @click="csvout()"> {{ $t("energy.enedata042") }} </a-button>
                </div>
                <a-table :style="{width: '100%', height: '300px',padding:'10px'}"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :scroll="xscroll"
                    size="small"
                >
                    <template v-for="col in showItems"
                        :slot="col"
                        slot-scope="text,record,index" >
                        <div :key="col" >
                            <span v-if="(index<2 || index>3) && ( text == '-' || parseInt(text)>=0)">{{ text }}</span>
                            <span style="color:green;" v-else-if="(index>1 && index<4) && ( text == '-' || parseInt(text)>=0)">{{ text }}</span>
                            <span style="color:red;" v-else>{{ text }}</span>
                        </div>
                    </template>
                </a-table>
                                
            </div>     
        </div> 
   
    </div>
</template>



<script>
import { getEnergyAnalysisYearData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";



export default {
  name: "energyAnalysisYearData",
  data() {
    
    return {
      loading: false,
      eneKindList:[],
      rpt:'',
      levelingTypeList:[],
      leveling:'',
      basicUnitList:[],
      basicUnit:'',
      dayTypeList:[],
      dayType:'',
      co2TypeList:[],
      co2Type:'',
      yearList:[],
      year:0,
      
      chartData:null,
      chartKind:'bar',
      reportName:'',
      levelingName:'',

      data:null,
      columns:null,
      pagination:false,
      xscroll: { x: 700 },

      typeDisable:false,
      showItems:['item2','item3','item4','item5','item6','item7','item8','item9','item10','item11','item12','item13'],
      areaStyle1:null,
      areaStyle2:null,
      echartStyle:{width: '100%', height: '500px'},
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.columns = [];
        this.data = [];
        this.initPage();
        this.drawDefultChart();
    }
  },
  mounted() { 
    // this.initPage();
    // this.drawDefultChart();
    //实现自适应部分
    window.addEventListener("resize", () => this.resizeChart());
    this.areaStyle1 = {
                opacity: 0.8,
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(255, 0, 135)'
                }, {
                    offset: 1,
                    color: 'rgba(135, 0, 157)'
                }])
            };
    this.areaStyle2 = {
                opacity: 0.8,
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(55, 162, 255)'
                }, {
                    offset: 1,
                    color: 'rgba(116, 21, 219)'
                }])
            };
  },
  beforeDestroy() {
   
  },
  methods:{
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    drawCharts(){
        if(this.chartData){
            let titleName = this.$t("energy.enedata062") +"-"+ this.reportName;
            let subtext = this.chartData.thisYear.unit;
            let option = null;
            let title  = {
                text: titleName,
                // subtext: subtext,
                ///left:"center"
            };
            if(this.leveling=='0'){
                option = this.getChartData(this.chartKind);
            }else{
                option = this.getLevelingChartData(this.chartKind);
            }
           
            if(this.chartKind=='pie'){
                title = option.title;
            }
            option && this.drawChart(title,subtext,option.legends,option.categorys,option.series,option.yAxis,option.vm);
        }
    },
    drawChart(title,subtext,legends,categorys,series,yAxis,vm){
      // 基于准备好的dom，初始化echarts实例
      let energyAnalysisYearDataChart = this.$echarts.init(document.getElementById("energyAnalysisYearDataChart"));
      // 绘制图表
      let option = null;
      if(this.chartKind=='pie'){
          option = {
              title:title,
              toolbox: {
                  show: true,
                  feature: {
                      mark: {show: true},
                      dataView: {show: true, readOnly: false},
                      restore: {show: true},
                      saveAsImage: {show: true}
                  }
              },
              tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} '+subtext+'({d}%)'
              },
              legend:legends,
              series: series
          };
      }else{
        option = {
            title: title,
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    dataView: {show: true, readOnly: false},
                    restore: {show: true},
                    saveAsImage: {show: true}
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legends
            },
            xAxis: {
                name:this.chartData.thisYear.xunit,
                type: 'category',
                data: categorys
            },
            yAxis:yAxis,
            visualMap:vm,
            series: series
        };
      }
      
      if(option){
        console.log(option)
        // 使用刚指定的配置项和数据显示图表。
        energyAnalysisYearDataChart.setOption(option,true);
        this.resizeChart();
      }
     
    },
    getLevelingPieChartData(){
        let charType = 'pie';
        let legends ={
            orient: 'vertical',
            left: 'left',
        };
        const data = this.chartData;
        let categorys = new Array();
        let series = new Array();
        
        let data1 = new Array();
        let data2 = new Array();
        let data3 = new Array();
        let data4 = new Array();

        let data5 = new Array();
        let data6 = new Array();
        let data7 = new Array();
        let data8 = new Array();
      
        categorys = data.thisYear.categories;

       
        let totalLeveling = new Array();

        let thisYearLeveling = new Array();
        let lastYearLeveling = new Array();
        let beforeLastLeveling = new Array();

        for (let i = 0; i < data.thisYear.data.length; i++) {
            let a = new Number(data.thisYear.data[i]+data.thisYearLvl.data[i]);
            let t = new Number(data.lastYear.data[i]+data.lastYearLvl.data[i]);
            let b = new Number(data.beforeLast.data[i]+data.beforeLastLvl.data[i]);
            thisYearLeveling.push(a.toFixed(3));
            lastYearLeveling.push(t.toFixed(3));
            beforeLastLeveling.push(b.toFixed(3));
            
        }

        for (let i = 0; i < data.yearTotal.categories.length; i++) {
            let a = new Number(data.yearTotal.data[i]+data.yearTotalLvl.data[i]);
            totalLeveling.push(a.toFixed(3));

            let label = null;
            if(totalLeveling[i]>0){
            //   label = {formatter: '{b} \n {c} '+data.yearTotal.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d =  {value: totalLeveling[i], name: data.yearTotal.categories[i],label:label};
            data8.push(d);

            label = null;
            if(a>0){
            //   label = {formatter: '{b} \n {c} '+data.yearTotal.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d7 =  {value: data.yearTotal.data[i], name: data.yearTotal.categories[i],label:label};
            data7.push(d7);
        }

        for(let i=0;i<categorys.length;i++){
            let label = null;
            if(data.thisYear.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.thisYear.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d =  {value: data.thisYear.data[i], name: categorys[i],label:label};
            data1.push(d);

            label = null;
            if(data.lastYear.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.lastYear.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d2 =  {value: data.lastYear.data[i], name: categorys[i],label:label};
            data2.push(d2);


            label = null;
            if(data.beforeLast.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.beforeLast.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d3 =  {value: data.beforeLast.data[i], name: categorys[i],label:label};
            data3.push(d3);

            label = null;
            if(thisYearLeveling[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.thisYearLvl.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d4 =  {value: thisYearLeveling[i], name: categorys[i],label:label};
            data4.push(d4);

            label = null;
            if(lastYearLeveling[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.lastYearLvl.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d5 =  {value: lastYearLeveling[i], name: categorys[i],label:label};
            data5.push(d5);

            label = null;
            if(beforeLastLeveling[i]*1>0){
              label = {formatter: '{b} \n {c} '+data.beforeLastLvl.unit+'({d}%)'};
            }
            let d6 =  {value: beforeLastLeveling[i], name: categorys[i],label:label};
            data6.push(d6);
        }
        let radius = [10, 60];
        let sery1 = {
            name: data.thisYear.year + " " + this.$t('energy.enedata071'),
            type: 'pie',
            radius: radius,
            center: ['20%', '20%'],
            selectedMode: 'single',
            data: data1,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery2 = {
            name: data.lastYear.year + " " + this.$t('energy.enedata071'),
            type: 'pie',
            radius: radius,
            center: ['50%', '20%'],
            selectedMode: 'single',
            data: data2,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery3 = {
            name: data.beforeLast.year + " " + this.$t('energy.enedata071'),
            type: 'pie',
            radius: radius,
            center: ['80%', '20%'],
            selectedMode: 'single',
            data: data2,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
        
        let sery4 = {
            name: data.thisYearLvl.year + " " + this.$t('energy.enedata073'),
            type: 'pie',
            radius:radius,
            center: ['20%', '50%'],
            selectedMode: 'single',
            data: data4,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery5 = {
            name: data.lastYearLvl.year + " " + this.$t('energy.enedata073'),
            type: 'pie',
            radius: radius,
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: data5,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

         let sery6 = {
            name: data.beforeLastLvl.year + " " + this.$t('energy.enedata073'),
            type: 'pie',
            radius:radius,
            center: ['80%', '50%'],
            selectedMode: 'single',
            data: data6,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }


        let sery7 = {
            name: data.yearTotal.name,// + " " + this.$t('energy.enedata071'),
            type: 'pie',
            radius: radius,
            center: ['20%', '80%'],
            selectedMode: 'single',
            data: data7,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery8 = {
            name: this.$t('energy.enedata074') + "-" + this.$t('energy.enedata073'),
            type: 'pie',
            radius: radius,
            center: ['50%', '80%'],
            selectedMode: 'single',
            data: data8,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
                
        series.push(sery3);
        series.push(sery2);
        series.push(sery1);
        series.push(sery6);
        series.push(sery5);
        series.push(sery4);
        series.push(sery7);
        series.push(sery8);

        let title = [{
                    text:  this.$t("energy.enedata062")+"-"+this.reportName,
                    left: 'center',
                }, {
                    subtext: sery1.name,
                    left: '20%',
                    top: '32%',
                    textAlign: 'center'
                }, {
                    subtext: sery2.name,
                    left: '50%',
                    top: '32%',
                    textAlign: 'center'
                },  {
                    subtext: sery3.name,
                    left: '80%',
                    top: '32%',
                    textAlign: 'center'
                }, {
                    subtext: sery4.name,
                    left: '20%',
                    top: '64%',
                    textAlign: 'center'
                }, {
                    subtext: sery5.name,
                    left: '50%',
                    top: '64%',
                    textAlign: 'center'
                }, {
                    subtext: sery6.name,
                    left: '80%',
                    top: '64%',
                    textAlign: 'center'
                }, {
                    subtext: sery7.name,
                    left: '20%',
                    bottom: '1%',
                    textAlign: 'center'
                },{
                    subtext: sery8.name,
                    left: '50%',
                    bottom: '1%',
                    textAlign: 'center'
                }];

    
        let option = {
            title:title,
            legends:legends,
            series:series,
            categorys:null,
            visualMap:null,
            yAxis:null
        };

        return option;
    },
    getPieChartData(){
        let charType = 'pie';
        let legends ={
            orient: 'vertical',
            left: 'left',
        };
        const data = this.chartData;
        let categorys = new Array();
        let series = new Array();
        let data1 = new Array();
        let data2 = new Array();
        let data3 = new Array();
        let data4 = new Array();
      
        categorys = data.thisYear.categories;

        for(let i=0;i<categorys.length;i++){
            let label = null;
            if(data.thisYear.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.thisYear.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d =  {value: data.thisYear.data[i], name: categorys[i],label:label};
            data1.push(d);

            label = null;
            if(data.lastYear.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.lastYear.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d2 =  {value: data.lastYear.data[i], name: categorys[i],label:label};
            data2.push(d2);

            label = null;
            if(data.beforeLast.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.beforeLast.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d3 =  {value: data.beforeLast.data[i], name: categorys[i],label:label};
            data3.push(d3);
        }

        for(let i=0;i<data.yearTotal.categories.length;i++){
            let  label = null;
            if(data.yearTotal.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.yearTotal.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d =  {value: data.yearTotal.data[i], name: data.yearTotal.categories[i],label:label};
            data4.push(d);
        }

        let sery1 = {
            name: data.thisYear.name,
            type: 'pie',
            radius: [10, 80],
            center: ['25%', '25%'],
            selectedMode: 'single',
            data: data1,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery2 = {
            name: data.lastYear.name,
            type: 'pie',
            radius: [10, 80],
            center: ['75%', '25%'],
            selectedMode: 'single',
            data: data2,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery3 = {
            name: data.beforeLast.name,
            type: 'pie',
            radius: [10, 80],
            center: ['25%', '75%'],
            selectedMode: 'single',
            data: data3,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery4 = {
            name: data.yearTotal.name,
            type: 'pie',
            radius: [10, 80],
            center: ['75%', '75%'],
            selectedMode: 'single',
            data: data4,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
                
        series.push(sery1);
        series.push(sery2);

        series.push(sery3);
        series.push(sery4);

        let title = [{
                    text:  this.$t("energy.enedata062")+"-"+this.reportName,
                    left: 'center',
                }, {
                    subtext: data.thisYear.name,
                    left: '25%',
                    top: '45%',
                    textAlign: 'center'
                }, {
                    subtext: data.lastYear.name,
                    left: '75%',
                    top: '45%',
                    textAlign: 'center'
                },{
                    subtext: data.beforeLast.name,
                    left: '25%',
                    bottom: '1%',
                    textAlign: 'center'
                }, {
                    subtext: data.yearTotal.name,
                    left: '75%',
                    bottom: '1%',
                    textAlign: 'center'
                }];

    
        let option = {
            title:title,
            legends:legends,
            series:series,
            categorys:null,
            visualMap:null,
            yAxis:null
        };

        return option;
    },
    getLevelingLineChartData(){
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();
        let yAxiArr = new Array();

        const data = this.chartData;

        categorys = data.thisYear.categories;

        let yAxi1 =  {
            name: data.thisYear.unit,
            type: 'value'
        };
        yAxiArr.push(yAxi1);


        let sery1 = {
            name: data.thisYear.year + " " + this.$t('energy.enedata071')  ,
            data: data.thisYear.data,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let sery2 = {
            name: data.lastYear.year + " " + this.$t('energy.enedata071') ,
            data: data.lastYear.data,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };


        let sery3 = {
            name: data.beforeLast.year + " " + this.$t('energy.enedata071') ,
            data: data.beforeLast.data,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };


        let thisYearLeveling = new Array();
        let lastYearLeveling = new Array();
        let beforeLastLeveling = new Array();

        for (let i = 0; i < data.thisYear.data.length; i++) {
            let a = new Number(data.thisYear.data[i]+data.thisYearLvl.data[i]);
            let t = new Number(data.lastYear.data[i]+data.lastYearLvl.data[i]);
            let b = new Number(data.beforeLast.data[i]+data.beforeLastLvl.data[i]);
            thisYearLeveling.push(a.toFixed(3));
            lastYearLeveling.push(t.toFixed(3));
            beforeLastLeveling.push(b.toFixed(3));
            
        }
        
        let sery4 = {
            name: data.thisYear.year + " " + this.$t('energy.enedata073') ,
            data: thisYearLeveling,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let sery5 = {
            name: data.lastYear.year + " " + this.$t('energy.enedata073') ,
            data: lastYearLeveling,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let sery6 = {
            name: data.beforeLast.year + " " + this.$t('energy.enedata073') ,
            data: beforeLastLeveling,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };


        series.push(sery3);
        series.push(sery2);
        series.push(sery1);
        series.push(sery6);
        series.push(sery5);
        series.push(sery4);

        legends.push(sery3.name);
        legends.push(sery2.name);
        legends.push(sery1.name);
        legends.push(sery6.name);
        legends.push(sery5.name);
        legends.push(sery4.name);
     
        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:[],
            yAxis:yAxiArr
        }

        return option;

    },
    getLevelingChartData(charType){
        if(this.chartKind==='line'){
            return this.getLevelingLineChartData();
        }else if(this.chartKind==='pie'){
            return this.getLevelingPieChartData();
        }
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();
        const data = this.chartData;
        categorys = data.thisYear.categories;


        let yAxiArr = new Array();
        let yAxi1 =  {
            name: data.thisYear.unit,
            type: 'value'
        };
        yAxiArr.push(yAxi1);

        let sery1 = {
            name: data.thisYear.name ,
            data: data.thisYear.data,
            type: charType,
            stack: 'THISYEAR',
            emphasis: {
                focus: 'series'
            }
        };

        let sery3 = {
            name: data.thisYearLvl.name ,
            data: data.thisYearLvl.data,
            type: charType,
            stack: 'THISYEAR',
            emphasis: {
                focus: 'series'
            }
        };

        

        let sery2 = {
            name: data.lastYear.name,
            data: data.lastYear.data,
            type: charType,
            stack: 'LASTYEAR',
            emphasis: {
                focus: 'series'
            }
        };

        let sery4 = {
            name: data.lastYearLvl.name,
            data: data.lastYearLvl.data,
            type: charType,
            stack: 'LASTYEAR',
            emphasis: {
                focus: 'series'
            }
        };


        let sery5 = {
            name: data.beforeLast.name,
            data: data.beforeLast.data,
            type: charType,
            stack: 'BFLASTYEAR',
            emphasis: {
                focus: 'series'
            }
        };

        let sery6 = {
            name: data.beforeLastLvl.name,
            data: data.beforeLastLvl.data,
            type: charType,
            stack: 'BFLASTYEAR',
            emphasis: {
                focus: 'series'
            }
        };

        if('line' === this.chartKind){
            // sery4.smooth=true;
            // sery3.smooth=true;
            // sery2.smooth=true;
            // sery1.smooth=true;

            // sery1.areaStyle= this.areaStyle1;
            // sery3.areaStyle= this.areaStyle1;
            // sery2.areaStyle= this.areaStyle2;
            // sery4.areaStyle= this.areaStyle2;

            // series.push(sery2);
            // series.push(sery1);
            // legends.push(data.target.name);
            // legends.push(data.actual.name);

        }else{
            series.push(sery5);
            series.push(sery2);
            series.push(sery1);

            series.push(sery6);
            series.push(sery4);
            series.push(sery3);

            legends.push(sery5.name);
            legends.push(sery2.name);
            legends.push(sery1.name);
            
            legends.push(sery6.name);
            legends.push(sery4.name);
            legends.push(sery3.name);
        }
                    
              
        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:[],
            yAxis:yAxiArr
        }

        return option;
    },
    getChartData(charType){
        if(charType=='pie'){
            return  this.getPieChartData();
        }
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();

        const data = this.chartData;
      

        let sTag = -1;
        let vm =  null;
        let yAxiArr = new Array();
        let yAxi1 =  {
                name: this.$t('energy.enedata069')+":"+data.thisYear.unit,
                type: 'value'
            };
        // let yAxi2 =  {
        //     name: this.$t('enedata070')+":"+data.yearTotal.unit,
        //     type: 'value'
        // };
        yAxiArr.push(yAxi1);
        //yAxiArr.push(yAxi2);
        
        categorys = data.thisYear.categories;
        //categorys = {...categorys,...data.yearTotal.categories}
        let sery1 = {
            name: data.thisYear.name ,
            data: data.thisYear.data,
            type: charType,
            smooth: true
        };

        let sery2 = {
            name: data.lastYear.name,
            data: data.lastYear.data,
            type: charType,
            smooth: true
        };

        let sery3 = {
            name: data.beforeLast.name,
            data: data.beforeLast.data,
            type: charType,
            smooth: true
        };
                    
        series.push(sery3);
        series.push(sery2);
        series.push(sery1);

        legends.push(sery3.name);
        legends.push(sery2.name);
        legends.push(sery1.name);

        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:vm,
            yAxis:yAxiArr
        }

        return option;
    },
    csvout(){
         let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1' || this.rpt=='2' || this.rpt=='3'){
            params.basicUnit = this.basicUnit;
            params.levelingType = this.leveling;
            if(this.rpt=='1'){
                params.timeType = this.dayType;
            }
        }else if(this.rpt=='4'){
            params.co2Type = this.co2Type;
        }
        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/analysis/year/download',params)
        .then((res) => {
            let name  = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.reportName+'-';
            // if(this.rpt!='4'){
            //   name +=this.pointTypeName+'-'
            // }
            name += moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      console.log('rptChange',e);
      let t = parseInt(e.target.value);
      this.reportName = this.eneKindList[t-1].text;
      this.chartData = null;
      this.columns = [];
      this.data = [];
      this.drawDefultChart();
      this.co2Type = this.co2TypeList[0].no;
      switch(t){
          case 1:
          break;
          case 2:
          break;
          case 3:
          break;
          case 4:
              this.leveling = this.levelingTypeList[0].no;
              this.basicUnit = this.basicUnitList[0].no;
          break;
          case 5:
              this.leveling = this.levelingTypeList[0].no;
              this.basicUnit = this.basicUnitList[0].no;
          break;
      }
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1' || this.rpt=='2' || this.rpt=='3'){
            params.basicUnit = this.basicUnit;
            params.levelingType = this.leveling;
            if(this.rpt=='1'){
                params.timeType = this.dayType;
            }
        }else if(this.rpt=='4'){
            params.co2Type = this.co2Type;
        }
        console.log("GetEnergyAnalysisYearData Params",params);
        this.loading = true;

        getEnergyAnalysisYearData(params)
        .then((res) => {
            
            console.log("GetEnergyAnalysisYearData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == -1){
                    this.$message.error(this.$t('energy.enedata065'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{
                    this.columns = res.data.columnList;
                    this.data    = res.data.bodyList;
                    if( this.leveling =='0'){
                        this.chartData = {
                            thisYear:res.data.thisYear,
                            lastYear:res.data.lastYear,
                            beforeLast:res.data.beforeLast,
                            yearTotal:res.data.yearTotal,
                        }
                    }else{
                        this.chartData = {
                            thisYear:res.data.thisYear,
                            lastYear:res.data.lastYear,
                            beforeLast:res.data.beforeLast,
                            yearTotal:res.data.yearTotal,
                            thisYearLvl:res.data.thisYearLvl,
                            lastYearLvl:res.data.lastYearLvl,
                            beforeLastLvl:res.data.beforeLastLvl,
                            yearTotalLvl:res.data.yearTotalLvl,
                        }
                    }
                    this.drawCharts();
                }             
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    yearChange(e){
       console.log('yearChange',e);
       this.search();
    },
    dayTypeChange(e){
       console.log('dayTypeChange',e);
       this.search();
    },
    co2TypeChange(e){
       console.log('co2TypeChange',e);
       this.search();
    },
    basicUnitChange(e){
        console.log('basicUnitChange',e);
        this.search();
    },
    levelingTypeChange(e){
      console.log('levelingTypeChange',e);
      if(e=='1'){
          this.dayType = '0';
          this.typeDisable = true;
      }else{
          this.typeDisable = false;
      }
      this.search();
    },
    initPage(){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyAnalysisYearData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.eneKindList			 =	res.data.eneKindList;
                this.rpt					 =	res.data.eneKindList[0].no;
                this.reportName              =  res.data.eneKindList[0].text;
                this.levelingTypeList		 =	res.data.levelingTypeList;
                this.leveling                =	res.data.levelingTypeList[0].no;
                this.basicUnitList			 =	res.data.basicUnitList;
                this.basicUnit               =	res.data.basicUnitList[0].no;
                this.dayTypeList			 =	res.data.dayTypeList;
                this.dayType                 =	res.data.dayTypeList[0].no;
                this.co2TypeList			 =	res.data.co2TypeList;
                this.co2Type                 =	res.data.co2TypeList[0].no;
                this.yearList				 =	res.data.yearList;
                this.year                    =	res.data.fiscalYear;
                this.monthList               =  res.data.monthList;
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    drawDefultChart(){
      let option =  this.getDefaultOption();
      // 基于准备好的dom，初始化echarts实例
      let energyAnalysisYearDataChart = this.$echarts.init(document.getElementById("energyAnalysisYearDataChart"));
      option && energyAnalysisYearDataChart.setOption(option, true);
      this.resizeChart();
    },
    getDefaultOption(){
        let category =  ['04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03']; 
        
        let option = {
            xAxis: {
                type: 'category',
                data: category,
            },
            yAxis: {
                type: 'value'
            },
            legend:{ },
            series: [{
                data: [0],
                type: 'line',
                smooth: true
            }]
        };
        return option;
    },
     resizeChart(){
      if(document.getElementById("energyAnalysisYearDataChart")){ 
          if( this.chartKind =='pie'){
            //   if(this.leveling=='1'){

            //   }else{

            //   }
                this.echartStyle.height = '900px';
                this.$echarts.init(document.getElementById("energyAnalysisYearDataChart")).resize({height:900});
          }else{
                this.echartStyle.height = '500px';
                this.$echarts.init(document.getElementById("energyAnalysisYearDataChart")).resize({height:500});
          }
         
      }
    },
    
  },
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyAnalysisYearData {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height:calc(100% - 160px);
    overflow: auto;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>


